<template>
    <the-table header="لاگین" icon="Logins" :tabs="tabs" :columns="columns" :items="items">
        <template #cell(icon)="{item}">
            <span class="d-flex-r-jc-ac">
                <inline-svg width="21" :src="require(`@/assets/Icons/${item.type}-icon.svg`)"/>
            </span>
        </template>
        <template #cell(status)="{item}">
            <span class="d-flex-r-jc-ac" :style="{'color' : `var(--${statusColor(item.status)})`}">
                <li><span>{{statusLabel(item.status)}}</span></li>
            </span>
        </template>
    </the-table>
</template>

<script>
    import TheTable from "@/components/Tools/TheTable";
    export default {
        name: "Login",
        components: {TheTable},
        data() {
            return {
                tabs: [
                    {
                        name: 'all',
                        label: 'همه'
                    },
                    {
                        name: 'withdraw',
                        label: 'ورود'
                    },
                    {
                        name: 'deposit',
                        label: 'خروج'
                    },
                ],
                columns:[
                    {
                        name : 'icon',
                        label : '',
                    },
                    {
                        name : 'createdAtDateTime',
                        label : 'زمان',
                        type : 'date',
                        dateType : 'faDateTime',
                        mobile : true
                    },
                    {
                        name : 'device',
                        label : 'دستگاه',
                    },
                    {
                        name : 'platform',
                        label : 'پلتفرم',
                        mobile : true
                    },
                    {
                        name : 'browser',
                        label : 'مرورگر',
                        mobile : true
                    },
                    {
                        name : 'IP',
                        label : 'آی پی',
                    },
                    {
                        name : 'status',
                        label : 'وضعیت',
                        mobile : true
                    },
                ],
                items:[
                    {
                        createdAtDateTime : '2021-08-02T15:12',
                        device : 'دستکتاپ',
                        platform : 'Windows',
                        browser : 'Chrome',
                        IP : '123.123.123.123',
                        status : 'DENIED',
                        type : 'LOGIN'
                    },
                    {
                        createdAtDateTime : '2021-08-02T15:12',
                        device : 'دستکتاپ',
                        platform : 'Windows',
                        browser : 'Chrome',
                        IP : '123.123.123.123',
                        status : 'COMPLETED',
                        type : 'LOGOUT'
                    },
                    {
                        createdAtDateTime : '2021-08-02T15:12',
                        device : 'دستکتاپ',
                        platform : 'Windows',
                        browser : 'Chrome',
                        IP : '123.123.123.123',
                        status : 'DENIED',
                        type : 'LOGIN'
                    },
                    {
                        createdAtDateTime : '2021-08-02T15:12',
                        device : 'دستکتاپ',
                        platform : 'Windows',
                        browser : 'Chrome',
                        IP : '123.123.123.123',
                        status : 'COMPLETED',
                        type : 'LOGOUT'
                    },
                ]
            }
        },
        computed:{
            statusLabel(){
                const a = {
                    'COMPLETED' : 'موفق',
                    'DENIED' : 'نا موفق',
                    'PENDING' : 'درحال بررسی',
                }
                return e => a[e]
            },
            statusColor(){
                const a = {
                    'COMPLETED' : 'buy',
                    'DENIED' : 'sell',
                    'PENDING' : 'yellow3',
                }
                return e => a[e]
            },
        }
    }
</script>

<style scoped>

</style>