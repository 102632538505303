<template>
    <form class="change-pass d-flex-c-jsf-ast-gap15 l-radius" @submit.prevent="submit" @change="validate($event.target.name)">
        <h3 class="label d-flex-ac-jfs-gap10">
            <i class="icon gradient-template-gold-trans-linear back xs-radius"/>
            تغییر گذرواژه
        </h3>
        <the-input v-model="data.currentPassword" type="password" :align="data.currentPassword? 'left' : 'right'"
                   :invalid-text="state.error.currentPassword" name="email" placeholder="گذرواژه فعلی"/>
        <the-input v-model="data.password" type="password" :align="data.password? 'left' : 'right'"
                   :invalid-text="state.error.password" name="password" placeholder="گذرواژه جدید"/>
        <the-input v-model="data.confirmationPass" type="password" :align="data.confirmationPass? 'left' : 'right'"
                   :invalid-text="state.error.confirmationPass" name="confirmationPass" placeholder="تکرار گذرواژه جدید"/>
        <the-button flat type="submit" class="gradient-template-linear-pink" @click="submit">تغییر گذرواژه</the-button>
    </form>
</template>

<script>
    import TheInput from "@/components/Tools/TheInput";
    import {validate} from "@/lib/reuseableFuncrtions";
    import TheButton from "@/components/Tools/TheButton";

    const errorTemplate = {
        currentPassword: {
            required: true,
        },
        password: {
            required: true,
            minLength: 8,
            regexName: 'password'
        },
        confirmationPass: {
            required: true,
        }
    }

    export default {
        name: "ChangePass",
        components: {TheButton, TheInput},
        data() {
            return {
                data: {
                    currentPassword: '',
                    password: '',
                    confirmationPass: '',
                },
            }
        },
        methods:{
            async submit() {
                if (await this.$validate(this.data, errorTemplate)) {
                    if (this.data.confirmationPass === this.data.password) {
                        this.$error('','تغییر گذرواژه با موفقیت انجام شد', 'successful','','',2000)
                    } else {
                        this.state.error = {confirmationPass : 'تکرار گذرواژه اشتباه است'}
                    }
                }
            },
            validate,
        }
    }
</script>

<style lang="scss">
.change-pass{
    background-color: var(--dark);
    padding: 25px;
    .label {
        .icon {
            padding: 4px;
            height: 28px;
            width: 28px;
        }
    }
}
</style>