<template>
    <div class="setting d-flex-r-w-g1-s1-gap15">
        <h2 class="p-30 fullWidth">تنظیمات</h2>
        <change-pass class="basis-300"/>
        <set-two-step-auth class="basis-300"/>
        <login class="fullWidth"/>
    </div>
</template>

<script>
    import ChangePass from "@/components/Panel/Setting/ChangePass";
    import SetTwoStepAuth from "@/components/Panel/Setting/SetTwoStepAuth/SetTwoStepAuth";
    import Login from "@/components/Panel/History/Login";
    export default {
        name: "Setting",
        components: {Login, SetTwoStepAuth, ChangePass}
    }
</script>

<style lang="scss">
    .setting {
        padding: 0 20px 20px;
        text-align: right;
        height: 100%;
    }
</style>