import { render, staticRenderFns } from "./SetTwoStepAuthModal.vue?vue&type=template&id=296ed932&scoped=true&"
import script from "./SetTwoStepAuthModal.vue?vue&type=script&lang=js&"
export * from "./SetTwoStepAuthModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "296ed932",
  null
  
)

export default component.exports