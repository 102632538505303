<template>
    <the-modal :label="'تنظیم شناسایی دو عاملی به ' + type.name" class="set-two-step-modal"
               :text="description"
               v-model="modal">
        <template #body>
            <form class="modal-body d-flex-c-ast-jst-gap20 p-15" @submit.prevent="submit">
                <template v-if="type.value === 'GOOGLE_AUTHENTICATOR'">
                    <img
                            class="align-center"
                            width="180px"
                            :src="'data:image/png;base64,'+qrCode"
                            alt=""
                    >
                    <a class="align-center" style="cursor: pointer" @click="Copy(qrLink)">Secret Key = {{qrLink}}</a>
                </template>
                <the-input v-model="data.code" :align="data.code? 'left' : 'right'"
                           :invalid-text="state.error.code" name="code" placeholder="کد تایید"/>
                <the-button flat-light type="submit" @click="submit">تایید</the-button>
            </form>
        </template>
    </the-modal>
</template>

<script>
    import TheModal from "@/components/Tools/TheModal";
    import {modal, validate} from "@/lib/reuseableFuncrtions";
    import TheInput from "@/components/Tools/TheInput";
    import TheButton from "@/components/Tools/TheButton";

    const text = {
        'EMAIL': 'کد ارسال شده به ایمیل خود را وارد نمایید.',
        'SMS': 'کد ارسال شده به شماره همراه وارد شده در حساب کاربری خود را وارد نمایید.',
        'GOOGLE_AUTHENTICATOR': 'لطفا بارکد زیر را توسط برنامه Google Authenticator گوشی خود اسکن نمایید.\n' +
            'پس از اسکن بارکد، مورد مربوط به صرافی زرکوین در برنامه شما ایجاد خواهد شد. برای تایید صحت فرآیند، کدی که در اپ خود مشاهده می‌کنید را در کادر زیر وارد نمایید.'
    }

    // eslint-disable-next-line no-unused-vars
    const errorTemplate = {
        code: {
            required: true,
            minLength: 5,
        }
    }

    export default {
        name: "SetTwoStepAuthModal",
        components: {TheButton, TheInput, TheModal},
        props: ['show','qrCode','qrLink','mobile','type'],
        model: {
            prop: 'show',
            event: 'close'
        },
        data(){
            return{
                data:{
                    code: ''
                }
            }
        },
        computed:{
            modal,
            description(){
                return text[this.type.value]
            }
        },
        methods:{
            async submit() {
                if (await this.$validate(this.data, errorTemplate)) {
                    this.$emit('submit',this.type)
                }
            },
            validate,
        }
    }
</script>

<style scoped>

</style>