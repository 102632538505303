<template>
    <div class="the-table s-radius d-flex-r-ast-jst-gap25">
        <div v-if="header || tabs" class="header d-flex-c-jfs-ast-gap25">
            <h3 v-if="header" class="label d-flex-ac-jfs-gap10">
                <inline-svg v-if="icon" :src="require('@/assets/Icons/'+ icon + '.svg')"
                            class="icon gradient-template-gold-trans-linear back xs-radius"/>
                {{header}}
            </h3>
            <div class="tabs-main">
                <div class="tabs xs-radius d-flex-c-ast" :style="{
                    '--number' : tabs.length,
                    '--selected' : selected
                }">
                    <span v-for="(tab,index) in tabs" :key="tab.name" class="tab-item d-flex-ac-jc"
                          @click="selected = index"
                          :class="{'active-tab' : tab.name===tabs[selected].name}">{{tab.label}}</span>
                </div>
            </div>
        </div>
        <perfect-scrollbar class="grow-1" dir="ltr">
            <table class="fullWidth table" dir="rtl">
                <tr class="table-row header-row">
                    <th :class="{'mobile-hidden' : !column.mobile}" v-for="(column,index) in columns" :key="index">
                        <slot :name="`header(${column.name})`" :column="column">
                            <span class="header-item">
                                {{column.label}}
                            </span>
                        </slot>
                    </th>
                </tr>
                <tr class="table-row content-row" v-for="(item,i) in items" :key="i">
                    <td :class="{'mobile-hidden' : !column.mobile}" v-for="(column,index) in columns" :key="index">
                        <slot :name="`cell(${column.name})`" :item="item" :column="column" :index="i">
                            <span :dir="column.type === 'date' ? 'ltr' : 'rtl'" class="header-item">
                                {{data(item,column) || ''}}
                            </span>
                        </slot>
                    </td>
                </tr>
            </table>
        </perfect-scrollbar>
    </div>
</template>

<script>
    let _ = require('lodash');

    export default {
        name: "TheTable",
        props: ['header', 'icon', 'tabs', 'columns', 'items'],
        data() {
            return {
                selected: 0
            }
        },
        methods: {
            getItem(item, key) {
                return _.get(item, key)
            },
            data(item, column) {
                const type = column.type || false
                const amount = _.get(item, column.name)
                return !type ? amount
                    : type === 'date' ? this.$G2J(amount, column.dateType || 'faDateTime')
                        : type === 'number' ? this.$toLocal(amount)
                            : amount
            }
        }
    }
</script>

<style lang="scss" scoped>
    .ps{
        height: 100%;
    }
    .the-table {
        padding: 15px;
        background-color: var(--dark);
        --table-tabs: #c4c4c4;
        --table-color: #a39797;
        --selected-color: var(--yellow3);
        height: 205px;

        .header {
            .label {
                .icon {
                    padding: 4px;
                    height: 28px;
                    width: 28px;
                }
            }

            .tabs-main {
                padding: 5px;
                min-width: 120px;

                .tabs {
                    border: 2px solid var(--yellow1);
                    background-color: var(--table-tabs);
                    color: var(--table-color);
                    overflow: hidden;
                    position: relative;
                    --number: 3;
                    --selected: 1;
                    z-index: 1;
                    box-sizing: content-box;

                    &:before {
                        z-index: 0;
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: calc(100% / var(--number));
                        /*height: 35px;*/
                        background-color: var(--selected-color);
                        border-radius: var(--xs-radius);
                        box-shadow: 0 2px 8px 0 #20151594;
                        transition: all 0.3s;
                        --position: calc((2 * var(--selected) + 1) / (2 * var(--number)));
                        top: calc(var(--position) * 100%);
                        transform: translateY(-50%);
                    }

                    .tab-item {
                        z-index: 1;
                        height: 35px;
                        cursor: pointer;
                        transition: color 0.3s;
                    }

                    .active-tab {
                        color: var(--white);
                        cursor: default;
                    }
                }
            }
        }

        .table{
            text-align: center;
            font-size: clamp(10px,1vw,14px);
            border-collapse: collapse;
            .table-row{
                &.header-row{
                    height: 35px;
                    border-bottom: 2px solid var(--border);
                    th{
                        background-color: var(--dark);
                        border-bottom: 2px solid var(--border);
                        position: sticky;
                        top: 0;
                    }
                }
                &.content-row{
                    height: 35px;
                }
            }
        }

        @media (max-width : 768px) {
            .mobile-hidden{
                display: none;
            }
            flex-direction: column;
            .header{
                flex-direction: row;
                .tabs-main{
                    .tabs{
                        flex-direction: row;
                        width: calc(var(--number) * 80px);
                        justify-content: space-between;
                        font-size: 12px;
                        .tab-item{
                            width: 80px;
                        }
                        &:before{
                            height: 100%;
                            width: calc(100% / var(--number));
                            top:0;
                            right: calc(var(--position) * 100%);
                            transform: translateX(50%);
                        }
                    }
                }
            }
        }
    }
</style>